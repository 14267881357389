import DefiUtils from 'defi-utils';
import { useTranslation } from 'next-i18next';
import { useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';

import WrappedImg from '@/components/WrappedImg';

import { useAppSelector } from '@/store/index';
import { landingSelector } from '@/store/landing';
import { protocolSelector } from '@/store/protocol';

import { formatNumber, nFormatter } from '@/utils/helpers';

import { ROUTES } from '@/types/enums';

const UseBubbleData = () => {
  const { t } = useTranslation('agora');
  const { totalValueLockedUSD } = useAppSelector(landingSelector);
  const {
    liquidStakingTao: { totalStakers: taoTotalStakers },
    liquidStaking: { totalStakers: egldTotalStakers },
    isLoadingFirstInfo
  } = useAppSelector(protocolSelector);

  const totalStakers = useMemo(() => {
    return new DefiUtils(taoTotalStakers).plus(egldTotalStakers).toString();
  }, [taoTotalStakers, egldTotalStakers]);

  const data = useMemo(
    () => [
      {
        id: 1,
        textContent: [
          ...t('c1', { joinArrays: '|' }).split('|'),
          isLoadingFirstInfo ? (
            <div>
              <Skeleton inline width={24} /> : <Skeleton width={60} />
            </div>
          ) : (
            t('tvl', { amount: `$${nFormatter(totalValueLockedUSD)}` })
          ),
        ],
        illustration: (
          <div className='absolute -right-8 md:-bottom-16 lg:-right-12'>
            <WrappedImg
              className='h-[267px]'
              src='https://cdn.app.hatom.com/images/agora/geometry1.png'
            />
          </div>
        ),
        lens: 'hash-avatar.svg',
        links: [
          {
            url: '/lend',
            name: 'Lending Protocol',
            arrow: true,
            primary: true,
          },
          {
            url: 'https://docs.hatom.com/hatom/hatoms-ecosystem/lending-protocol',
            name: t('translation:learn-more'),
            external: true,
          },
        ],
        live: true,
      },
      {
        id: 2,
        textContent: t('c2', { joinArrays: '|' })
          .split('|')
          .map((e, i, array) => {
            if (i === array.length - 1)
              return (
                <>
                  {isLoadingFirstInfo ? (
                    <Skeleton inline width={46} />
                  ) : (
                    `+${formatNumber(totalStakers, 0)}`
                  )}{' '}
                  {e}
                </>
              );

            return e;
          }),
        illustration: (
          <div className='absolute -bottom-6 -right-0 lg:-bottom-2'>
            <WrappedImg
              className='h-[217px]'
              src='https://cdn.app.hatom.com/images/agora/geometry2.png'
            />
          </div>
        ),
        lens: 'htm.png',
        links: [
          {
            url: ROUTES.LIQUID_APP,
            name: 'Liquid staking',
            arrow: true,
            primary: true,
          },
          {
            url: ROUTES.LIQUID,
            name: t('translation:learn-more'),
          },
        ],
        live: true,
      },
      {
        id: 3,
        tiny: true,
        textContent: t('c3', { joinArrays: '|' }).split('|'),
        illustration: (
          <div className='absolute -bottom-1 -right-0'>
            <WrappedImg
              className='h-[147.27px]'
              src='https://cdn.app.hatom.com/images/agora/geometry3.png'
            />
          </div>
        ),
        links: [
          {
            url: '/lend',
            name: t('htm-booster'),
          },
        ],
        live: true,
      },
      {
        id: 4,
        tiny: true,
        textContent: t('c4', { joinArrays: '|' }).split('|'),
        illustration: (
          <div className='absolute -bottom-11 -right-12'>
            <WrappedImg
              className='h-[224px]'
              src='https://cdn.app.hatom.com/images/agora/geometry4.png'
            />
          </div>
        ),
        links: [
          {
            url: '/bridge',
            name: t('tao-bridge'),
          },
        ],
        live: true,
      },
      {
        id: 5,
        tiny: true,
        textContent: t('c5', { joinArrays: '|' }).split('|'),
        illustration: (
          <div className='absolute bottom-1 -right-0'>
            <WrappedImg
              className='h-[158px]'
              src='https://cdn.app.hatom.com/images/agora/geometry5.png'
            />
          </div>
        ),
        links: [
          {
            url: 'https://www.ush.io/',
            name: t('ush-staking'),
          },
        ],
        live: true,
      },
    ],
    [isLoadingFirstInfo, totalValueLockedUSD, totalStakers],
  );

  return data;
};

export default UseBubbleData;
